// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-arama-js": () => import("./../../src/pages/arama.js" /* webpackChunkName: "component---src-pages-arama-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kategori-js": () => import("./../../src/pages/kategori.js" /* webpackChunkName: "component---src-pages-kategori-js" */),
  "component---src-templates-word-amp-js": () => import("./../../src/templates/word.amp.js" /* webpackChunkName: "component---src-templates-word-amp-js" */),
  "component---src-templates-word-js": () => import("./../../src/templates/word.js" /* webpackChunkName: "component---src-templates-word-js" */)
}

